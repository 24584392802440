import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from 'redux/reducer';
import config from 'config';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reduxPollingMiddleware } from 'redux-polling';

import globalApiMiddleware from 'redux/slices/globalApi';

const { DEV_TOOLS } = config;

// ======================================================
// Store Enhancers
// ======================================================
let composeEnhancer = compose;

const persistConfig = {
    key: 'authenticating',
    storage,
    blacklist: [
        'router',
        'REDUX_POLLING',
        'config',
        'processedData',
        'idProperties',
        'captureProperties',
        'images',
        'integration',
        'loader',
        'dashboard',
        'toaster',
        'search',
        'themeEditor',
        'eep',
    ],
};
if (DEV_TOOLS && DEV_TOOLS.enableReduxDevTools) {
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
}

// ======================================================
// Store Instantiation
// ======================================================

const persistedReducer = persistReducer(persistConfig, reducer);
const middleware = composeEnhancer(
    applyMiddleware(thunk, reduxPollingMiddleware, globalApiMiddleware)
);

export default function initStore(initialState = {}) {
    let store = createStore(persistedReducer, middleware);
    let persistor = persistStore(store);
    window.store = store;
    return { store, persistor };
}

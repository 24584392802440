const Production = require('./production');
const Localhost = require('./localhost');
const Staging = require('./staging');
const Beta = require('./beta');

const config = {
    production: Production,
    staging: Staging,
    localhost: Localhost,
    beta: Beta,
};

// get app environment
const env = process.env.REACT_APP_BUILD_ENV || '';
const configFile = config[env] || '';

// export config file
module.exports = configFile;

import { Redirect, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import endPoints from './endpoints';
import Loader from 'components/Loader';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { RedirectRoute } from './RedirectRoute';
import DefaultWorkflow from 'views/Workflows/DefaultWorkflow';

//Layout
const Onboarding = lazy(() => import('layouts/Onboarding'));
const Dashboard = lazy(() => import('layouts/Dashboard'));

//screen

const Login = lazy(() => import('views/Login'));
const TwoFA = lazy(() => import('views/2FA'));
const ResetPassword = lazy(() => import('views/ResetPassword'));
const SetResetPassword = lazy(() => import('views/SetResetPassword'));
const VerifyEmail = lazy(() => import('views/VerifyEmail'));
const SignupV2 = lazy(() => import('views/SignupV2'));
const Home = lazy(() => import('views/Home'));
const BillingDashboard = lazy(() => import('views/BillingDashboard'));
const Workflows = lazy(() => import('views/Workflows'));
const Integration = lazy(() => import('views/Integration'));
const NotFound = lazy(() => import('views/NotFound'));
const Settings = lazy(() => import('views/Settings'));
const Guides = lazy(() => import('views/Guides'));
const TermsAndPrivacy = lazy(() => import('views/TermsAndPrivacy'));
const EmbeddedDocument = lazy(() => import('views/Home/SignDocument/EmbeddedDocument'));
const TCM = lazy(() => import('views/TCM'));
const Users = lazy(() => import('views/Users'));
const Logout = lazy(() => import('views/Logout'));

const Routes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <PublicRoute
                    path={endPoints.default}
                    component={() => (
                        <Onboarding>
                            <Login />
                        </Onboarding>
                    )}
                    exact
                />

                <PublicRoute
                    path={endPoints.twoFA}
                    component={() => (
                        <Onboarding>
                            <TwoFA />
                        </Onboarding>
                    )}
                    exact
                />

                <PublicRoute
                    path={endPoints.resetPassword}
                    component={() => (
                        <Onboarding>
                            <ResetPassword />
                        </Onboarding>
                    )}
                    exact
                />
                <PublicRoute
                    path={`${endPoints.setResetPassword}/:token`}
                    component={() => (
                        <Onboarding>
                            <SetResetPassword type="forgot-password" />
                        </Onboarding>
                    )}
                    exact
                />

                <PublicRoute
                    path={`${endPoints.setInvitePassword}/:token`}
                    component={() => (
                        <Onboarding>
                            <SetResetPassword type="invite-password" />
                        </Onboarding>
                    )}
                    exact
                />

                <PublicRoute
                    path={`${endPoints.verifyEmail}`}
                    component={() => <VerifyEmail />}
                    exact
                />

                <PublicRoute path={endPoints.register} component={() => <SignupV2 />} exact />

                <PublicRoute path={endPoints.consent} component={() => <TermsAndPrivacy />} exact />

                {/* Redirecting to the new user profile link */}
                <PrivateRoute
                    path={`${endPoints.dashboard}/user/:uuid`}
                    component={({ match }) => <Redirect to={`/users/${match.params.uuid}`} />}
                    exact
                />

                <PrivateRoute
                    path={`${endPoints.users}/user/:uuid`}
                    component={({ match }) => <Redirect to={`/users/${match.params.uuid}`} />}
                    exact
                />

                <PrivateRoute
                    path={endPoints.userSearch}
                    component={() => <Redirect to={endPoints.users} />}
                    exact
                />

                <PrivateRoute
                    path={endPoints.dashboard}
                    component={() => (
                        <Dashboard>
                            <Home />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.users}
                    component={() => (
                        <Dashboard>
                            <Users />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.billingDashboard}
                    component={() => (
                        <Dashboard>
                            <BillingDashboard />
                        </Dashboard>
                    )}
                    exact
                />
                <PrivateRoute path={endPoints.workflows} component={() => <Workflows />} />
                <PrivateRoute
                    path={endPoints.integration}
                    component={() => (
                        <Dashboard>
                            <Integration />
                        </Dashboard>
                    )}
                />
                <PrivateRoute
                    path={endPoints.settings}
                    component={() => (
                        <Dashboard>
                            <Settings />
                        </Dashboard>
                    )}
                    exact
                />
                <PrivateRoute path={endPoints.verifyUi} component={DefaultWorkflow} exact />

                <PrivateRoute
                    path={endPoints.guides}
                    component={() => (
                        <Dashboard>
                            <Guides />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.tcm}
                    component={() => (
                        <Dashboard>
                            <TCM />
                        </Dashboard>
                    )}
                />

                <PrivateRoute
                    path={endPoints.signDocument}
                    component={() => <EmbeddedDocument />}
                    exact
                />

                <PrivateRoute path={endPoints.logout} component={() => <Logout />} />

                <Route
                    path="/index.html"
                    component={() => <RedirectRoute pathname="/" includeQueryParams={true} />}
                />
                <Route path="*" component={NotFound} />
            </Switch>
        </Suspense>
    );
};
export default Routes;

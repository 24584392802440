import { combineReducers } from 'redux';
import auth from './auth';
import apiCall from './apiCall';
import integration from './integration';
import toaster from './toaster';
import loader from './loader';
import themeEditor from './themeEditor';
import settings from './settings';
import dashboard from './dashboard';
import billing from './billing';
import search from './search';
import workflow from './workflow';
import tcm from './tcm';
import pollApi from '../slices/polling';
import { globalAPI } from '../slices/globalApi';
import { reduxPollingNamespace, reduxPollingReducer } from 'redux-polling';
import users from './users';
import eep from './eep';

const reducer = combineReducers({
    [reduxPollingNamespace]: reduxPollingReducer,
    auth,
    apiCall,
    integration,
    toaster,
    loader,
    themeEditor,
    settings,
    dashboard,
    billing,
    search,
    workflow,
    pollApi,
    tcm,
    [globalAPI.reducerPath]: globalAPI.reducer,
    users,
    eep,
});

export default reducer;

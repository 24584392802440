import actionDispatcher from 'api/actionDispatcher';
import actionType from './actionConstant';
import {
    resetPasswordApi,
    login2FAApi,
    registerApi,
    verifyTokenApi,
    setForgetPasswordApi,
    updatePasswordApi,
    refreshCompanyToken,
    fetchAdminInformationApi,
    setInvitePasswordApi,
    logoutApi,
} from 'api/auth';

export const login2FAAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: login2FAApi.bind(null, Data),
        actionTypeSuccess: actionType.LOGIN_SUCCESS,
        actionTypeFailure: actionType.LOGIN_FAILURE,
        actionTypeInProgress: actionType.LOGIN_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const registerAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: registerApi.bind(null, Data),
        actionTypeSuccess: actionType.REGISTER_SUCCESS,
        actionTypeFailure: actionType.REGISTER_FAILURE,
        actionTypeInProgress: actionType.REGISTER_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
export const resetPasswordAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: resetPasswordApi.bind(null, Data),
        actionTypeSuccess: actionType.RESET_PASSWORD_SUCCESS,
        actionTypeFailure: actionType.RESET_PASSWORD_FAILURE,
        actionTypeInProgress: actionType.RESET_PASSWORD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const verifyTokenAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: verifyTokenApi.bind(null, Data),
        actionTypeSuccess: actionType.VERIFY_TOKEN_SUCCESS,
        actionTypeFailure: actionType.VERIFY_TOKEN_FAILURE,
        actionTypeInProgress: actionType.VERIFY_TOKEN_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setForgetPasswordAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: setForgetPasswordApi.bind(null, Data),
        actionTypeSuccess: actionType.SET_FORGET_PASSWORD_SUCCESS,
        actionTypeFailure: actionType.SET_FORGET_PASSWORD_FAILURE,
        actionTypeInProgress: actionType.SET_FORGET_PASSWORD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const updatePasswordAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: updatePasswordApi.bind(null, Data, token),
        actionTypeSuccess: actionType.UPDATE_PASSWORD_SUCCESS,
        actionTypeFailure: actionType.UPDATE_PASSWORD_FAILURE,
        actionTypeInProgress: actionType.UPDATE_PASSWORD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const updateCompanyDetailAction = (type, payload) => {
    return {
        type,
        payload,
    };
};

export const refreshCompanyAction = ({ token, companyUUID }) =>
    actionDispatcher({
        serviceMethod: refreshCompanyToken.bind(null, { companyUUID, token }),
        actionTypeSuccess: actionType.AUTH.COMPANY.REFRESH.SUCCESS,
        actionTypeFailure: actionType.AUTH.COMPANY.REFRESH.FAILURE,
        actionTypeInProgress: actionType.AUTH.COMPANY.REFRESH.INPROGRESS,
        extra: null,
        commonConfig: { loader: false },
        callback: () => {},
    });

export const fetchAdminInformationAction = (
    token,
    commonConfig = { loader: false },
    cb = () => {}
) =>
    actionDispatcher({
        serviceMethod: fetchAdminInformationApi.bind(null, { token }),
        actionTypeSuccess: actionType.FETCH_ADMIN_INFORMATION_SUCCESS,
        actionTypeFailure: actionType.FETCH_ADMIN_INFORMATION_FAILURE,
        actionTypeInProgress: actionType.FETCH_ADMIN_INFORMATION_REQUEST,
        extra: null,
        commonConfig: commonConfig,
        callback: cb,
    });

export const logoutAction = (token, commonConfig = { loader: true }, cb = () => {}) =>
    actionDispatcher({
        serviceMethod: logoutApi.bind(null, { token }),
        actionTypeSuccess: actionType.RESET_ALL_REDUCERS,
        actionTypeFailure: actionType.LOGOUT_FAILURE,
        actionTypeInProgress: actionType.LOGOUT_REQUEST,
        extra: { token },
        commonConfig: commonConfig,
        callback: cb,
    });

export const clearToken = () => {
    return {
        type: actionType.AUTH.CLEAR_TOKEN,
    };
};

export const loginAction = (data) => {
    return {
        type: actionType.LOGIN_SUCCESS,
        payload: data,
    };
};

export const setInvitePasswordAction = (Data, commonConfig, cb) =>
    actionDispatcher({
        serviceMethod: setInvitePasswordApi.bind(null, Data),
        actionTypeSuccess: actionType.SET_INVITE_PASSWORD_SUCCESS,
        actionTypeFailure: actionType.SET_INVITE_PASSWORD_FAILURE,
        actionTypeInProgress: actionType.SET_INVITE_PASSWORD_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

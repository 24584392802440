import actionType from 'redux/actions/actionConstant';
import { setUser, setUserProperty } from 'helper/datadog';

const initialState = {
    userData: undefined,
    forgetPasswordData: undefined,
    adminData: undefined,
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case actionType.LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
            };
        case actionType.STORE_TO_STATE: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case actionType.VERIFY_TOKEN_SUCCESS: {
            return {
                ...state,
                forgetPasswordData: action.payload,
            };
        }
        case actionType.FETCH_ADMIN_INFORMATION_SUCCESS: {
            const company = state.userData?.user?.company ?? {};

            setUser({
                first_name: action.payload.admin.first_name,
                last_name: action.payload.admin.last_name,
                uuid: action.payload.admin.uuid,
                company_name: company.company_name ?? null,
                company_id: company.uuid ?? null,
            });

            return {
                ...state,
                adminData: action.payload.admin,
            };
        }
        // Both below have the same end result.
        case actionType.AUTH.COMPANY.REFRESH.SUCCESS:
        case actionType.UPDATE_COMPANY_DETAILS: {
            let updatedData =
                action.type === actionType.AUTH.COMPANY.REFRESH.SUCCESS
                    ? action.payload.company
                    : action.payload;

            setUserProperty('companyName', updatedData.company_name);
            setUserProperty('companyId', updatedData.uuid);

            return {
                ...state,
                userData: {
                    ...state.userData,
                    user: {
                        ...state.userData.user,
                        company: {
                            ...state.userData.user.company,
                            ...updatedData,
                        },
                    },
                },
            };
        }

        case actionType.AUTH.CLEAR_TOKEN: {
            let _state = { ...state };
            if (_state?.userData?.token) {
                _state.userData.token = null;
            }

            return {
                ..._state,
            };
        }
        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                userData: undefined,
                forgetPasswordData: undefined,
            };
        default:
            return state;
    }
}

import { ERR_MESSAGES } from 'constants';
import actionType from 'redux/actions/actionConstant';
import routeEnpoints from 'route/endpoints';

const getErrorMessage = (error) => {
    const errorObj = error?.response?.text && JSON.parse(error.response.text);

    if (error.response) {
        return error.response.body.errorMessage;
    } else if (errorObj?.httpcode) {
        return errorObj.httpcode;
    } else if (error.message) {
        return error.message;
    }

    return errorObj.errorMessage;
};

const handleUnauthorizedError = () => {
    window.location.href = routeEnpoints.logout;
};

const hideErrMessage = (error, message) => {
    const exculdeErrMsg = [
        ERR_MESSAGES.NO_INTEGRATIONS_FOUND,
        ERR_MESSAGES.NO_MVR_REPORT_FOUND,
        ERR_MESSAGES.ACTION_NOT_ALLOWED_FOR_DECEASED_USER,
    ];

    const errorStatus = error?.response?.status >= 400 && error?.response?.status < 500;

    const hideError =
        errorStatus &&
        exculdeErrMsg.map((item) => item.toLowerCase()).includes(message.toLowerCase());

    return hideError;
};

/**
 * Middleware for API call
 * @param {*} serviceMethod API method
 * @param {*} actionTypeSuccess SUCCESS action constant for API call
 * @param {*} actionTypeFailure FAILURE action constant for API call
 * @param {*} actionTypeInProgress REQUEST action constant for API call
 * @param {*} extra Extra params used in reducer for handling
 * @param {*} commonConfig Object to handle loader, success and failure of api.
 * @param {*} callback A callback function if required to handle anything.
 */
function ActionDispatcher({
    serviceMethod,
    actionTypeSuccess,
    actionTypeFailure,
    actionTypeInProgress,
    extra,
    commonConfig = {
        loader: false,
    },
    callback,
}) {
    return (dispatch) => {
        if (commonConfig.loader) {
            dispatch({
                type: actionType.START_LOADER,
            });
        }

        dispatch({
            type: actionTypeInProgress,
            extra,
        });

        serviceMethod()
            .then((response) => {
                if (commonConfig.loader) {
                    dispatch({
                        type: actionType.STOP_LOADER,
                    });
                }
                const { body, text } = response;

                if (body) {
                    if (body.error) {
                        const message = body.error;
                        dispatch({
                            type: actionTypeFailure,
                            message: message,
                        });
                    } else {
                        dispatch({
                            type: actionTypeSuccess,
                            payload: body,
                        });
                    }
                } else {
                    //for downloading csv
                    const blob = new Blob([text], { type: 'data:text/csv;charset=utf-8,' });
                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.download = 'Data.csv';
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
                        ':'
                    );
                    anchor.click();
                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 100);
                }

                if (callback) callback(response);
            })
            .catch((error) => {
                if (commonConfig.loader) {
                    dispatch({
                        type: actionType.STOP_LOADER,
                    });
                }

                if (typeof error.response !== 'undefined' && error.response.status === 401) {
                    handleUnauthorizedError();
                } else {
                    const errMessage = getErrorMessage(error);
                    const hide = hideErrMessage(error, errMessage);

                    if (!hide) {
                        dispatch({
                            type: actionType.OPEN_TOASTER,
                            payload: {
                                variant: 'error',
                                message: errMessage,
                            },
                        });
                    }
                }
            });
        return '';
    };
}

export default ActionDispatcher;

// Action Dispatcher for User-Dashboard APIs

export function userActionDispatcher({
    serviceMethod,
    actionTypeSuccess,
    actionTypeFailure,
    actionTypeInProgress,
    extra,
    commonConfig = {
        loader: false,
    },
    callback,
}) {
    return (dispatch) => {
        if (commonConfig.loader) {
            dispatch({
                type: actionType.START_LOADER,
            });
        }

        dispatch({
            type: actionTypeInProgress,
            extra,
        });

        serviceMethod()
            .then((response) => {
                if (commonConfig.loader) {
                    dispatch({
                        type: actionType.STOP_LOADER,
                    });
                }
                const { body, status } = response;

                if (body?.error) {
                    const message = body.error;
                    dispatch({
                        type: actionTypeFailure,
                        message: message,
                    });
                } else {
                    dispatch({
                        type: actionTypeSuccess,
                        payload: body,
                        status: status,
                    });
                }

                if (callback) callback(response);
            })
            .catch((error) => {
                if (commonConfig.loader) {
                    dispatch({
                        type: actionType.STOP_LOADER,
                    });
                }

                dispatch({
                    type: actionTypeFailure,
                    payload: {
                        variant: 'error',
                        error: error,
                    },
                });

                if (typeof error.response !== 'undefined' && error.response.status === 401) {
                    handleUnauthorizedError();
                } else {
                    const errMessage = getErrorMessage(error);
                    const hide = hideErrMessage(error, errMessage);

                    if (!hide) {
                        dispatch({
                            type: actionType.OPEN_TOASTER,
                            payload: {
                                variant: 'error',
                                message: errMessage,
                            },
                        });
                    }
                }
            });
        return '';
    };
}

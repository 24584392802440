import React from 'react';
import { connect } from 'react-redux';
// components
import { closeToast } from 'redux/actions/toaster';
import Toaster from './Toaster';

function ToastAdapter({ closeToast, variant, message, open }) {
    return <Toaster closeToast={closeToast} variant={variant} message={message} open={open} />;
}

function mapStateToProps(state) {
    return {
        open: state.toaster.open,
        message: state.toaster.message,
        variant: state.toaster.variant,
    };
}

export default connect(mapStateToProps, {
    closeToast,
})(ToastAdapter);

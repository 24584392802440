'use client';

import { ErrorBoundary } from 'react-error-boundary';
import React from 'react';
import PropTypes from 'prop-types';
import { ANALYTICS, ENV } from 'config';
import Button from 'components/Button';
import { datadogRum } from '@datadog/browser-rum';
import routeEnpoints from 'route/endpoints';

const logError = (error, info) => {
    // @see https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    if (ANALYTICS.DATADOG.RUM.ENABLED === 'true') datadogRum.addError(renderingError, { info });
};

function Fallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    if (ENV !== 'production') console.log({ error });

    return (
        <div style={{ marginTop: '20px' }}>
            <div>Something Went Wrong...</div>
            <Button
                variant={false}
                btnText="Refresh"
                className=""
                handleOnClick={() => resetErrorBoundary()}
            />
            <Button
                variant={false}
                btnText="Logout"
                className=""
                handleOnClick={() => (window.location.href = routeEnpoints.logout)}
            />
        </div>
    );
}

Fallback.propTypes = {
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
};

const CustomErrorBoundary = ({ children }) => {
    if (process.env.REACT_APP_BUILD_ENV === 'localhost')
        return <ErrorBoundary>{children}</ErrorBoundary>;

    return (
        <ErrorBoundary onError={logError} FallbackComponent={Fallback}>
            {children}
        </ErrorBoundary>
    );
};

CustomErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CustomErrorBoundary;
